export const ScannerIcon = [
  {
    name: 'scanner:reload',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g  clip-path="url(#clip0_9844_71670)"><path d="M17.6498 6.35C16.1998 4.9 14.2098 4 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.7298 20 18.8398 17.45 19.7298 14H17.6498C16.8298 16.33 14.6098 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L12.9998 11H19.9998V4L17.6498 6.35Z" fill="currentColor"/></g><defs><clipPath id="clip0_9844_71670"><rect width="24" height="24" fill="transparent"/></clipPath></defs></svg>'
  },
  {
    name: 'scanner:close',
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9851_20358)"><path d="M12.6663 4.27301L11.7263 3.33301L7.99967 7.05967L4.27301 3.33301L3.33301 4.27301L7.05967 7.99967L3.33301 11.7263L4.27301 12.6663L7.99967 8.93967L11.7263 12.6663L12.6663 11.7263L8.93967 7.99967L12.6663 4.27301Z" fill="currentColor"/></g><defs><clipPath id="clip0_9851_20358"><rect width="16" height="16" fill="transparent"/></clipPath></defs></svg>'
  },
  {
    name: 'scanner:share',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9844_71666)"><path opacity="0.3" d="M18 6C18.5523 6 19 5.55228 19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5C17 5.55228 17.4477 6 18 6Z" fill="currentColor"/><path opacity="0.3" d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z" fill="currentColor"/><path opacity="0.3" d="M18 20.0195C18.5523 20.0195 19 19.5718 19 19.0195C19 18.4672 18.5523 18.0195 18 18.0195C17.4477 18.0195 17 18.4672 17 19.0195C17 19.5718 17.4477 20.0195 18 20.0195Z" fill="currentColor"/><path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08ZM18 4C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6C17.45 6 17 5.55 17 5C17 4.45 17.45 4 18 4ZM6 13C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11C6.55 11 7 11.45 7 12C7 12.55 6.55 13 6 13ZM18 20.02C17.45 20.02 17 19.57 17 19.02C17 18.47 17.45 18.02 18 18.02C18.55 18.02 19 18.47 19 19.02C19 19.57 18.55 20.02 18 20.02Z" fill="currentColor"/></g><defs><clipPath id="clip0_9844_71666"><rect width="24" height="24" fill="transparent"/></clipPath></defs></svg>'
  },
  {
    name: 'scanner:copy',
    icon: ' <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="5.83398" y="5.3335" width="8" height="8" rx="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.166 5.33317V3.99984C11.166 3.26346 10.5691 2.6665 9.83268 2.6665H4.49935C3.76297 2.6665 3.16602 3.26346 3.16602 3.99984V9.33317C3.16602 10.0696 3.76297 10.6665 4.49935 10.6665H5.83268" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>'
  }
];
